//游戏大厅

import React, {Component} from 'react';
import Container from '../component/container'
import PlatformCard from '../component/platformCard'
import SearchBar from '../component/searchBar'
import style from '../utils/config.scss'
import model from "../services/model";
import PublicUtils from "../utils/publicUtils";
import FloatBtn from '../component/floatBtn'
import '../assets/css/animation.css'
import global from "../global";
import Image from "../component/image";


class Games extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItemIndex: 0
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const styles = {
            container: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
            },
            searchBarBox: {
                display: "flex",
                paddingTop: 20,
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
            },
            topContainer: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#fff"
                // borderBottomWidth: 1,
                // borderBottomColor: "#f1f1f1"
            },
            bottomContainer: {
                display: "flex",
                flexDirection: "column",
            },
            gameTitle: {
                display: "flex",
                flex: 1,
                height: 50,
                paddingLeft: 10,
                paddingRight: 5,
                justifyContent: 'center',
                alignItems: 'center',
            },
            gameActiveTitle: {
                // borderBottomWidth: 1,
                // borderBottomColor: style['primaryColor' + model.SKINNAME]
            },
            gameTitleText: {
                fontSize: 16
            },
            gameActiveTitleText: {
                color: style['primaryColor' + model.SKINNAME],
                fontWeight: 'bold'
            },


            gameBox: {
                paddingLeft: 10,
                backgroundColor: "#fff",
                display: "flex"
            },
            gameInnerBox: {
                flex: 1,
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid " + style['backgrountColor' + model.SKINNAME],
                paddingTop: 10,
                paddingBottom: 10,
                overflow: "hidden"
            },

            gameIconBox: {
                display: "flex",
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
            gameIcon: {
                display: "flex",
                width: 60,
                height: 60,
                backgroundColor: style['primaryColor' + model.SKINNAME],
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 3
            },

            gameInfo: {
                marginTop: 5,
                flex: 1,
                display: "flex",
                fontSize: 12,
                color: "#999",
                lineHeight: "17px",
            },

            gameDetailBox: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginLeft: 10,
                paddingRight: 10,
                width: 0
            },
            gameInfoTextBox: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
        };
        return (
            <Container tabIndex={2} showBottomTab={true} showHeader={false} style={{backgroundColor: "#fff"}}>
                <div style={styles.container}>
                    <div className={'headPadding'}>
                        <div style={{...styles.searchBarBox, ...{backgroundColor: "#fff"}}}>
                            <SearchBar onPress={() => {
                                PublicUtils.navigate('SearchView');
                            }}/>
                        </div>
                    </div>
                    <div style={styles.topContainer}>
                        {model.gameList.map((item, i) => {
                            return (item.name && item.type_id != 8) ? <div key={item.name} onClick={(item) => {
                                this.setState({activeItemIndex: i});
                            }}
                                                                           style={{...styles.gameTitle, ...i === this.state.activeItemIndex ? styles.gameActiveTitle : null}}>
                                <div
                                    style={{...styles.gameTitleText, ...i === this.state.activeItemIndex ? styles.gameActiveTitleText : null}}>{item.name}</div>
                            </div> : null
                        })}
                    </div>
                    <div style={{backgroundColor: "#f1f1f1", flex: 1, height: 10}}/>





                    {this.state.activeItemIndex == 1 && global.ID== 'ar' || this.state.activeItemIndex == 1 && global.ID== 'bw' || this.state.activeItemIndex == 1 && global.ID== 'br' || this.state.activeItemIndex == 1 && global.ID== 'ct'?<div>

                        <div onClick={() => {

                            let parm = {
                                "id": 1001,
                                "is_name": "香港六合彩",
                                "is_function": "lhc",
                                "is_number": 0,
                                "is_status": 2,
                                "is_img": "lhc",
                                "tmp": "LHC"
                            }

                          model.isgl = [parm];
                          model.isid = parm.id;
                            PublicUtils.navigate('LHC', parm);


                        }} style={styles.gameBox}>
                            <div style={styles.gameInnerBox}>
                                <div style={styles.gameIconBox}>
                                    <div style={styles.gameIcon}>
                                        <Image style={{width: 30}} resizeMode='contain'
                                               uri={'https://newpic.hdyb33.com/bw/lhc-xg.png'}/>
                                    </div>
                                </div>
                                <div style={styles.gameDetailBox}>
                                    <div style={styles.gameName}>
                                       香港六合彩
                                    </div>

                                    <div style={styles.gameInfo}>
                                        <div
                                          style={styles.gameInfoTextBox}></div>
                                    </div>
                                </div>
                            </div>
                        </div>





                      <div onClick={() => {

                        let parm = {
                          "id": 10015,
                          "is_name": "澳门六合彩(新)",
                          "is_function": "lhc",
                          "is_number": 1,
                          "is_status": 1,
                          "is_img": "amlhc",
                          "tmp": "LHC"
                        }

                        model.isgl = [parm];
                        model.isid = parm.id;


                        PublicUtils.navigate('LHC', parm);


                      }} style={styles.gameBox}>
                        <div style={styles.gameInnerBox}>
                          <div style={styles.gameIconBox}>
                            <div style={styles.gameIcon}>
                              <Image style={{width: 30}} resizeMode='contain'
                                     uri={'https://newpic.hdyb33.com/bw/lhc-am.png'}/>
                            </div>
                          </div>
                          <div style={styles.gameDetailBox}>
                            <div style={styles.gameName}>
                              澳门六合彩(新)
                            </div>
                            <div style={styles.gameInfo}>
                              <div
                                  style={styles.gameInfoTextBox}></div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>:<div></div>}

                    <div style={styles.bottomContainer}>
                        {
                            model.gameList[this.state.activeItemIndex].gamelist.map((item, key) => {
                                return <PlatformCard key={key} obj={item} icon={item.icon} name={item.name} isix={this.state.activeItemIndex}
                                                     memo1={item.memo1}
                                                     memo2={item.memo2}/>
                            })
                        }
                    </div>
                </div>
                {/**/}
                <FloatBtn/>
            </Container>
        )
    }
}

export default Games;
