/**
 * 皇冠体育
 * @format
 * @flow
 */
import React from 'react';
import Container from "../../../component/container";
import PublicUtils from "../../../utils/publicUtils";
import Http from "../../../services/http";
import Storage from "../../../services/storage";
import model from "../../../services/model";
import ActivityIndicator from "../../../component/activityIndicator";
import style from '../../../utils/config.scss'
import MyInput from "../../../component/myInput";

export default class HgBetting extends React.Component {

    // 构造
    constructor(props) {
        super(props);
        this.state = {
            data: PublicUtils.getState(this.props.location.pathname).data,
            template: PublicUtils.getState(this.props.location.pathname).template,
            playId: PublicUtils.getState(this.props.location.pathname).playId,
            ballId: PublicUtils.getState(this.props.location.pathname).ballId,
            betId: PublicUtils.getState(this.props.location.pathname).betId,
            betinfo: PublicUtils.getState(this.props.location.pathname).betinfo,
            min: 0,
            max: 0,
            betAmount: "",//投注金额
            canWin: 0,//可赢金额
            showProgress: false,
            refreshOddTime: this.refreshOddTime,
            showDetail: false,
            balance: 0,
        };
        this._init_();
    }

    refreshOddTime = 10;
    amountTemplate = [
        {
            name: "10",
            value: 10
        },
        {
            name: "20",
            value: 20
        },
        {
            name: "50",
            value: 50
        },
        {
            name: "100",
            value: 100
        },
        {
            name: "500",
            value: 500
        },
        {
            name: "1000",
            value: 1000
        }
    ];

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchMaxBetAmount();
    };
    /**
     * 比赛队伍
     * @private
     */
    _renderBetTeam = () => {

        const styles = {
            betModal: {},
            betInnerBox: {
                backgroundColor: "#fff",
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
            },
            betInnerBody: {},
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3,
            },
            betAmountInputWrapper: {
                borderWidth: 1,
                borderColor: "#f5f7f9",
                height: 40,
                flex: 1,
                borderRadius: 4,
                fontSize: 16,
            },
            betAmountInput: {
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 16,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 16,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 16,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 16,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 16,
                color: "#e8ab09"
            },
            betOddsTips: {
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#c73838"
            },
            betTips: {
                color: "#c73838"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgBox: {
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            betInfoBox: {
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                borderRadius: 3,
                marginBottom: 10,
                border: "1px solid #f5f7f9",
            },
            betInfoHeadRow: {
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "rgba(0,0,0,0.03)",
            },
            betInfoHeadCol: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            },
            betInfoHeadColText: {
                color: "rgba(0,0,0,0.2)"
            },
            betInfoBodyRow: {
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            betInfoBodyCol: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            },
            betInfoBodyColText: {
                fontSize: 16
            },
            betCGList: {
                margin: 10,
                borderRadius: 3,
                border: "1px solid #eff1f3",
                overflow: "hidden"
            },
            betCGHeaderInfo: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: 'space-between',
                padding: 10,
                backgroundColor: "#f5f7f9",
                borderRadius: 3
            },
            betCGCloseBtn: {
                display: "flex",
                borderTop: "1px solid #f1f3f5",
                backgroundColor: "#f1f3f5",
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: 'center',
                flexDirection: "row",
                alignItems: "center"
            },
            betCGCloseText: {
                color: style['primaryColor' + model.SKINNAME]
            }
        };
        const matchStyles = {
            matchInfoBox: {
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                flex: 1
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center"
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'stretch',
                flex: 1
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingRight: 20
            },
            arrowRight: {
                transform: [{rotate: '-90deg'}]
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottomColor: "#f5f5f5",
                borderBottomWidth: 10
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 10,
                paddingBottom: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                flex: 1,
                height: "100%",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                textAlign: "center",
                fontSize: 14,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                borderBottomColor: "#f5f5f5",
                borderBottomWidth: 1,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        if (this.state.playId === 0 || this.state.playId === 1 || this.state.playId === 2) {
            return (
                <div>
                    <div style={matchStyles.childMatchHeader}>
                        <div style={{...matchStyles.childMatchHeaderCol}}>
                            <div style={matchStyles.childMatchNameBox}>
                                <div ellipsizeMode={'middle'}
                                     style={matchStyles.childMatchName}>{this.state.betinfo._GQ_JR_ZP_GJ_.info[2]}</div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    width: 30,
                                    marginLeft: 5,
                                    backgroundColor: "#f7b000",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 3
                                }}>
                                <div style={{borderRadius: 3, color: "#fff"}}>主</div>
                            </div>
                        </div>
                        <div style={matchStyles.childMatchHeaderMid}>
                            <div style={matchStyles.childMatchHeaderMidText}>VS</div>
                        </div>
                        <div style={matchStyles.childMatchHeaderCol}>
                            <div
                                style={{
                                    display: "flex",
                                    width: 30,
                                    marginRight: 5,
                                    backgroundColor: "#f7b000",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 3
                                }}>
                                <div style={{borderRadius: 3, color: "#fff"}}>客</div>
                            </div>
                            <div style={matchStyles.childMatchNameBox}>
                                <div ellipsizeMode={'middle'}
                                     style={matchStyles.childMatchName}>{this.state.betinfo._GQ_JR_ZP_GJ_.info[3]}</div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.betInfoBox}>
                        <div style={styles.betInfoHeadRow}>
                            <div style={styles.betInfoHeadCol}>
                                <div
                                    style={styles.betInfoHeadColText}>玩法
                                </div>
                            </div>
                            <div style={styles.betInfoHeadCol}>
                                <div
                                    style={styles.betInfoHeadColText}>投注内容
                                </div>
                            </div>
                            <div style={styles.betInfoHeadCol}>
                                <div
                                    style={styles.betInfoHeadColText}>赔率[{this.state.refreshOddTime}]
                                </div>
                            </div>
                        </div>
                        <div style={styles.betInfoBodyRow}>
                            <div style={styles.betInfoBodyCol}>
                                <div
                                    style={styles.betInfoBodyColText}>{this.state.betinfo._GQ_JR_ZP_GJ_.gameTypeName}</div>
                            </div>
                            <div style={styles.betInfoBodyCol}>
                                <div
                                    style={styles.betInfoBodyColText}>{this.state.betinfo._GQ_JR_ZP_GJ_.betContent}{this.state.betinfo._GQ_JR_ZP_GJ_.pk}</div>
                            </div>
                            <div style={styles.betInfoBodyCol}>
                                <div
                                    style={styles.betInfoBodyColText}>@{this.state.betinfo._GQ_JR_ZP_GJ_.odds}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.playId === 4) {
            return (
                <div style={styles.betCGList}>
                    <div
                        style={styles.betCGHeaderInfo}>
                        <div style={styles.betMatchTeam}>串关</div>
                        <div style={styles.footerBetAmountBox}>
                            <div style={styles.footerBetAmountItem}>{this.state.betinfo._CG_.list.length}</div>
                            <div style={styles.footerBetAmountItem}>串</div>
                            <div style={styles.footerBetAmountItem}>1</div>
                            <div style={styles.footerBetAmountItem}>@ {this.state.betinfo._CG_.odds}</div>
                        </div>
                    </div>
                    {
                        this.state.showDetail ?
                            <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                                {
                                    this.state.betinfo._CG_.list.map((item) => {
                                        return (

                                            <div style={styles.betInfoBox}>
                                                <div style={{
                                                    ...matchStyles.childMatchHeader, ...{
                                                        paddingTop: 3,
                                                        paddingBottom: 3
                                                    }
                                                }}>
                                                    <div style={{...matchStyles.childMatchHeaderCol}}>
                                                        <div
                                                            style={{...matchStyles.childMatchNameBox, ...{backgroundColor: style['primaryColor' + model.SKINNAME]}}}>
                                                            <div
                                                                ellipsizeMode={'middle'}
                                                                style={{...matchStyles.childMatchName, ...{color: "#fff"}}}>{item.info[2]}</div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: 30,
                                                                marginLeft: 5,
                                                                backgroundColor: "#f7b000",
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: 3
                                                            }}>
                                                            <div style={{
                                                                borderRadius: 3,
                                                                color: "#fff"
                                                            }}>主
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={matchStyles.childMatchHeaderMid}>
                                                        <div
                                                            style={matchStyles.childMatchHeaderMidText}>VS
                                                        </div>
                                                    </div>
                                                    <div style={matchStyles.childMatchHeaderCol}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: 30,
                                                                marginRight: 5,
                                                                backgroundColor: "#f7b000",
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: 3
                                                            }}>
                                                            <div style={{
                                                                borderRadius: 3,
                                                                color: "#fff"
                                                            }}>客
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            ...matchStyles.childMatchNameBox, ...{
                                                                backgroundColor: style['primaryColor' + model.SKINNAME],
                                                                color: "#fff"
                                                            }
                                                        }}>
                                                            <div
                                                                ellipsizeMode={'middle'}
                                                                style={{...matchStyles.childMatchName, ...{color: "#fff"}}}>{item.info[3]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={styles.betInfoHeadRow}>
                                                    <div style={styles.betInfoHeadCol}>
                                                        <div
                                                            style={styles.betInfoHeadColText}>玩法
                                                        </div>
                                                    </div>
                                                    <div style={styles.betInfoHeadCol}>
                                                        <div
                                                            style={styles.betInfoHeadColText}>投注内容
                                                        </div>
                                                    </div>
                                                    <div style={styles.betInfoHeadCol}>
                                                        <div
                                                            style={styles.betInfoHeadColText}>赔率
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={styles.betInfoBodyRow}>
                                                    <div style={styles.betInfoBodyCol}>
                                                        <div
                                                            style={styles.betInfoBodyColText}>{item.gameTypeName}</div>
                                                    </div>
                                                    <div style={styles.betInfoBodyCol}>
                                                        <div
                                                            style={styles.betInfoBodyColText}>{item.betContent}{item.pk}</div>
                                                    </div>
                                                    <div style={styles.betInfoBodyCol}>
                                                        <div
                                                            style={styles.betInfoBodyColText}>@{item.odds}</div>
                                                    </div>
                                                </div>
                                                <div onClick={() => {
                                                    this._deleteBet();
                                                }} style={{
                                                    textAlign: "center",
                                                    background: "#f5f7f9",
                                                    color: "red",
                                                    padding: 10
                                                }}>删除
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : null
                    }
                    <div onClick={() => {
                        this.setState({showDetail: !this.state.showDetail});
                    }} style={styles.betCGCloseBtn}>
                        <div
                            style={styles.betCGCloseText}>{this.state.showDetail ? "收起" : "详情"}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{
                        justifyContent: 'center',
                        alignItems: "center",
                        fontSize: 18,
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: style['primaryColor' + model.SKINNAME]
                    }}>
                        <div
                            style={{
                                ...styles.betMatchTeam, ...{
                                    color: "#fff",
                                    textAlign: 'center'
                                }
                            }}>{this.state.betinfo._GQ_JR_ZP_GJ_.info[1]}</div>
                    </div>
                    <div style={styles.betInfoBox}>
                        <div style={styles.betInfoHeadRow}>
                            <div style={styles.betInfoHeadCol}>
                                <div
                                    style={styles.betInfoHeadColText}>玩法
                                </div>
                            </div>
                            <div style={styles.betInfoHeadCol}>
                                <div
                                    style={styles.betInfoHeadColText}>投注内容
                                </div>
                            </div>
                            <div style={styles.betInfoHeadCol}>
                                <div
                                    style={styles.betInfoHeadColText}>赔率[{this.state.refreshOddTime}]
                                </div>
                            </div>
                        </div>
                        <div style={styles.betInfoBodyRow}>
                            <div style={styles.betInfoBodyCol}>
                                <div
                                    style={styles.betInfoBodyColText}>{this.state.betinfo._GQ_JR_ZP_GJ_.gameTypeName}</div>
                            </div>
                            <div style={styles.betInfoBodyCol}>
                                <div
                                    style={styles.betInfoBodyColText}>{this.state.betinfo._GQ_JR_ZP_GJ_.betContent}{this.state.betinfo._GQ_JR_ZP_GJ_.pk}</div>
                            </div>
                            <div style={styles.betInfoBodyCol}>
                                <div
                                    style={styles.betInfoBodyColText}>@{this.state.betinfo._GQ_JR_ZP_GJ_.odds}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    /**
     * 加载状态
     * */
    _showProgress = () => {
        return <ActivityIndicator size="small" color={'#fff'}
                                  style={{marginLeft: 5, position: "absolute"}}/>;
    };

    /**
     * 最大投注金额
     * @private
     */
    _fetchMaxBetAmount = () => {
        let parm = {
            item: this.state.playId,
            ball: this.state.ballId,
            type: this.state.betinfo._GQ_JR_ZP_GJ_.gameId,
            bet_bs: this.state.betinfo._GQ_JR_ZP_GJ_.bs,
            bet_class: this.state.betinfo._GQ_JR_ZP_GJ_.typeid,
            bet_content: this.state.betinfo._GQ_JR_ZP_GJ_.gid,
        }
        if (this.state.playId === 4) {
            let bet_bs_list = [];
            let bet_class_list = [];
            let bet_content_list = [];
            for (let i = 0; i < this.state.betinfo._CG_.list.length; i++) {
                bet_bs_list.push(this.state.betinfo._CG_.list[i].bs);
                bet_class_list.push(this.state.betinfo._CG_.list[i].typeid);
                bet_content_list.push(this.state.betinfo._CG_.list[i].gid);
            }
            parm.item = 0;
            parm.type = 11;
            parm.bet_bs = bet_bs_list[0];
            parm.bet_class = bet_class_list[0];
            parm.bet_content = bet_content_list[0];
        }
        Http.request("sportMaxBetAmount", parm, (res) => {
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate('Login', {
                    tips: model.tips.loginTimeOut,
                    refresh: this._init_
                });
            }
            if (res.code === 200) {
                if (res.data && res.data.status === 1) {
                    this.state.playId !== 4 && this._refreshOddTime();
                    let state = {min: res.data.min, max: res.data.max, balance: res.data.balance};
                    if (this.state.playId !== 4 && this.state.playId !== 5) {
                        state.betinfo = this.state.betinfo;
                        state.betinfo._GQ_JR_ZP_GJ_.pk = res.data.bet[5];
                        state.betinfo._GQ_JR_ZP_GJ_.odds = res.data.bet[6];
                    }
                    if (this.state.playId === 5) {
                        state.betinfo = this.state.betinfo;
                        state.betinfo._GQ_JR_ZP_GJ_.odds = res.data.bet[2];
                    }
                    this.setState(state);
                } else {
                    model.betinfo._CG_ = {list: [], odds: ""};
                    PublicUtils.showToast(res.data.message);
                    PublicUtils.goBack(null);
                }
            }
        });
    };


    _refreshOddTime = () => {
        this.timer = window.setInterval(() => {
            if (this.state.refreshOddTime > 0) {
                this.setState({refreshOddTime: this.state.refreshOddTime - 1});
            } else {
                this._fetchMaxBetAmount();
                this.setState({refreshOddTime: this.refreshOddTime});
                window.clearInterval(this.timer);
            }
        }, 1000);
    };

    /**
     *投注金额
     */
    setBetAmount(amount) {

        let totalOdd = this.getTotalOdd();
        let canwin = amount * totalOdd;
        if (this.props.playId === 4) {
            canwin = amount * (totalOdd - 1);
        }
        this.setState({betAmount: amount, canWin: canwin});
    }

    /**
     * 计算串关玩法总赔率
     */
    getTotalOdd = () => {
        let totalOdd = 1;
        const _CG_ = this.state.betinfo._CG_.list;
        const _GQ_JR_ZP_GJ_ = this.state.betinfo._GQ_JR_ZP_GJ_;
        if (this.state.playId === 4) {
            for (let i = 0; i < _CG_.length; i++) {
                if (_CG_[i].typeid === 3 || _CG_[i].typeid === 4 || _CG_[i].typeid === 5 || _CG_[i].typeid === 6 || _CG_[i].typeid === 14) {
                    totalOdd = totalOdd * (1 + Number(_CG_[i].odds));
                } else {
                    totalOdd = totalOdd * _CG_[i].odds;
                }
            }
        } else {
            if (_GQ_JR_ZP_GJ_.typeid === 3 || _GQ_JR_ZP_GJ_.typeid === 4 || _GQ_JR_ZP_GJ_.typeid === 5 || _GQ_JR_ZP_GJ_.typeid === 6 || _GQ_JR_ZP_GJ_.typeid === 14) {
                totalOdd = totalOdd * Number(_GQ_JR_ZP_GJ_.odds);
            } else {
                totalOdd = totalOdd * (_GQ_JR_ZP_GJ_.odds - 1);
            }
        }
        let betinfo = {
            _GQ_JR_ZP_GJ_: _GQ_JR_ZP_GJ_,
            _CG_: {list: _CG_, odds: PublicUtils.subStringNum(totalOdd)}
        };
        this.setState({betinfo: betinfo});
        return totalOdd;
    };
    /**
     * 确认投注
     */
    beting = () => {
        if (!this.state.betAmount) {
            PublicUtils.showToast("请输入投注金额", {type: "FAILD"});
            return;
        }
        if (Number(this.state.betAmount) > Number(this.state.max) || Number(this.state.betAmount) < Number(this.state.min)) {
            PublicUtils.showToast("投注限额为（" + this.state.min + "~" + this.state.max + "）元", {type: "FAILD"});
            return;
        }
        let parm = {
            item: this.state.playId,
            ball: this.state.ballId,
            type: this.state.betinfo._GQ_JR_ZP_GJ_.gameId,
            bet_bs: this.state.betinfo._GQ_JR_ZP_GJ_.bs,
            bet_class: this.state.betinfo._GQ_JR_ZP_GJ_.typeid,
            bet_content: this.state.betinfo._GQ_JR_ZP_GJ_.gid,
            bet_amount: this.state.betAmount
        }
        if (this.state.playId === 4) {
            let bet_bs_list = [];
            let bet_class_list = [];
            let bet_content_list = [];
            for (let i = 0; i < this.state.betinfo._CG_.list.length; i++) {
                bet_bs_list.push(this.state.betinfo._CG_.list[i].bs);
                bet_class_list.push(this.state.betinfo._CG_.list[i].typeid);
                bet_content_list.push(this.state.betinfo._CG_.list[i].gid);
            }
            parm.item = 0;
            parm.type = 11;
            parm.bet_bs = JSON.stringify(bet_bs_list);
            parm.bet_class = JSON.stringify(bet_class_list);
            parm.bet_content = JSON.stringify(bet_content_list);
        }
        this.setState({showProgress: true});
        Http.request("sportBetting", parm, (res) => {
            this.setState({showProgress: false});
            // this._closeModal();
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'HG'});
            }
            if (res.code === 200) {
                if (res.data && res.data.status === 1) {
                    model.betinfo._CG_ = {list: [], odds: ""};
                    this.setBetAmount(" ");
                    // this.props.onBetSuccess && this.props.onBetSuccess(this.state.betAmount);
                    PublicUtils.showToast(res.data.message);
                } else if (res.data.status === 6) {
                    PublicUtils.showToast(res.data.message);
                    Storage.clearData();
                    PublicUtils.navigate("Login", {tips: model.tips.loginTimeOut, nextPage: 'HG'});
                } else {
                    PublicUtils.showToast(res.data.message, {type: "FAILD"});
                }
            }
        });
    };
    /**
     * 删除过关投注
     * @param item
     * @private
     */
    _deleteBet = (item) => {
        let betList = this.state.betinfo._CG_.list;
        betList.splice(item, 1);
        let betinfo = this.state.betinfo;
        betinfo._CG_.list = betList;
        model.betinfo._CG_.list = betList;
        if (betList.length === 0) {
            PublicUtils.goBack();
        }
        this.getTotalOdd();
    };

    render() {
        // const ButtonOpacity = createThemedComponent(TouchableOpacity);
        // const Icon = createThemedComponent(MyIcon, ['color']);

        let title = "投注详情";
        if (this.state.playId === 0 || this.state.playId === 1 || this.state.playId === 2) {
            title = this.state.betinfo._GQ_JR_ZP_GJ_.info[1];
        } else if (this.state.playId === 4) {
            title = "串关";
        } else {
            title = "冠军";
        }
        const styles = {
            betModal: {},
            betInnerBox: {
                backgroundColor: "#fff",
                overflow: "hidden"
            },
            betInnerTitle: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                padding: 10,
            },
            betInnerBody: {},
            btnBox: {
                marginTop: 10,
            },
            btn: {
                display: "flex",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                borderRadius: 3,
            },
            btnText: {
                color: "#fff"
            },
            active: {
                backgroundColor: style['primaryColor' + model.SKINNAME],
            },
            activeText: {
                color: "#fff"
            },
            disabledBtnText: {
                color: "#000"
            },
            betInnerTitleText: {
                fontSize: 16,
                color: "#000"
            },
            betModalRow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 30
            },
            amountTemplateWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                paddingTop: 10,
                paddingBottom: 10
            },
            betModalRowBetween: {
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                marginTop: 3,
                marginBottom: 3,
            },
            betAmountInputWrapper: {
                border: "1px solid #f1f3f5",
                backgroundColor: "#f5f7f9",
                height: 50,
                flex: 1,
                borderRadius: 4,
                fontSize: 16,
            },
            betAmountInput: {
                padding: 0,
                flex: 1,
                paddingLeft: 10
            },
            betMatchTeam: {
                fontSize: 16,
                color: "#000",
            },
            betMatchVs: {
                fontSize: 15,
                color: "rgba(245, 72, 68, 0.93)",
                marginLeft: 5,
                marginRight: 5
            },
            betPlayName: {
                fontSize: 16,
                color: "rgba(0,0,0,0.5)",
            },
            betContent: {
                fontSize: 16,
                color: "#e8ab09",
                marginRight: 5
            },
            betPk: {
                fontSize: 16,
                color: "rgba(0,0,0,0.5)",
                marginRight: 5
            },
            betSymbol: {
                fontSize: 16,
                color: "rgba(0,0,0,0.3)",
                marginRight: 5
            },
            betOdds: {
                fontSize: 16,
                color: "#e8ab09"
            },
            betOddsTips: {
                borderRadius: 4,
                border: "1px dashed #ddd",
                height: 30,
                paddingLeft: 10,
                paddingRight: 10,
            },
            betOddsTipsText: {
                color: "#c73838"
            },
            amountTemplateBox: {
                display: "flex",
                backgroundColor: "#ffb852",
                borderRadius: 4,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 5
            },
            amountTemplateText: {
                color: "#fff"
            },
            betCanWin: {
                color: "#000"
            },
            betTips: {
                color: "#c73838"
            },
            footerBetAmountBox: {
                display: "flex",
                flexDirection: "row"
            },
            footerBetAmountItem: {
                marginLeft: 5,
                color: "#000",
                fontSize: 16
            },
            cgBox: {
                backgroundColor: "#fffbf2",
                border: "1px solid #f5f5f5",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 5
            },
            cgRow: {
                display: "flex",
                height: 25,
                flexDirection: "row",
                alignItems: "center"
            },
            cgRowLeftCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgText: {marginRight: 5},
            cgRowRightCol: {
                display: "flex",
                flexDirection: "row",
            },
            cgVSText: {
                marginLeft: 5,
                marginRight: 5
            },
            cgdel: {
                display: "flex",
                width: 20,
                height: 20,
                borderRadius: 20,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ed4343"
            },
            betInfoBox: {
                display: "flex",
                flexDirection: "column",
                border: "1px solid #f5f7f9",
                overflow: "hidden",
                borderRadius: 3,
                marginBottom: 10
            },
            betInfoHeadRow: {
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "rgba(0,0,0,0.03)",
            },
            betInfoHeadCol: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            },
            betInfoHeadColText: {
                color: "rgba(0,0,0,0.2)"
            },
            betInfoBodyRow: {
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: "center",
                alignItems: "center"
            },
            betInfoBodyCol: {
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            },
            betInfoBodyColText: {
                fontSize: 16
            },
        };
        const matchStyles = {
            matchInfoBox: {
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f5f5f5"
            },
            matchHeaderButton: {
                flex: 1
            },
            matchHeaderBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
                height: 45,
                alignItems: "center"
            },
            matchChildBox: {},
            matchNameBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'stretch',
                flex: 1
            },
            matchName: {
                color: "#333",
                fontSize: 13,
                paddingRight: 20
            },
            arrowRight: {
                transform: [{rotate: '-90deg'}]
            },
            matchChildCount: {
                display: "flex",
                height: 20,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 20,
                backgroundColor: "#ef4e70",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            },
            matchChildCountText: {
                color: "#fff",
                fontSize: 10,
            },
            matchDetailList: {
                borderBottomColor: "#f5f5f5",
                borderBottomWidth: 10
            },
            top: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
            },
            playTypeIconBox: {
                display: "flex",
                flexDirection: "row",
            },
            playTypeIcon: {
                marginRight: 5
            },
            bigSize: {
                fontSize: 14
            },
            themeText: {
                color: style['primaryColor' + model.SKINNAME]
            },
            childMatchHeader: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                justifyContent: "center",
                backgroundColor: style['primaryColor' + model.SKINNAME],
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 10,
                paddingBottom: 10,
            },
            childMatchHeaderCol: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center"
            },
            childMatchHeaderMid: {
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            childMatchHeaderMidText: {
                color: "#e7d91b",
                fontSize: 16,
                marginLeft: 5,
                marginRight: 5,
            },
            childMatchNameBox: {
                display: "flex",
                flex: 1,
                height: "100%",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 0
            },
            childMatchName: {
                fontSize: 14,
                color: "#333",
                overflow: 'hidden',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            gjTop: {
                borderBottomColor: "#f5f5f5",
                borderBottomWidth: 1,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
            },
            gjChildList: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
            },
            gjChildItem: {
                display: "flex",
                width: "50%",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                borderRight: "1px solid #f5f7f9",
                borderBottom: "1px solid #f5f7f9",
            },
            fc0: {
                color: "#10a649"
            },
            fc1: {
                color: "#e8ab09"
            },
            fc2: {
                color: "#d53411"
            },
            btns: {
                borderRadius: 3
            }
        };
        return (
            <Container title={title} showMenuButton={true}
                       menuArray={[{name: "比赛结果", icon: "jqkj", href: 'SportOpenResult'}, {
                           name: "投注记录",
                           icon: "tzjl",
                           href: 'SportBetRecord'
                       }]}
                       style={{backgroundColor: "#fff"}}>

                <div style={styles.betModal}>
                    <div style={{...styles.betInnerBox}}>
                        <div>
                            <div style={styles.betInnerBody}>
                                {
                                    this._renderBetTeam()
                                }


                                <div style={{paddingLeft: 10, paddingRight: 10}}>
                                    <div style={styles.betModalRowBetween}>
                                        <div style={styles.betAmountInputWrapper}>
                                            <MyInput
                                                value={this.state.betAmount && String(this.state.betAmount)}
                                                placeholder={'限额（' + this.state.min + '~' + this.state.max + '）元'}
                                                style={styles.betAmountInput}
                                                onChangeText={(text) => {
                                                    this.setBetAmount(text.replace(/\D/g, ''));
                                                }}
                                            />
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <div style={{
                                                ...styles.betModalRow, ...{
                                                    marginLeft: 10,
                                                    height: 25,
                                                    fontSize: 16
                                                }
                                            }}>
                                                <div style={{color: "rgba(0,0,0,0.4)", fontSize: 12}}>可赢：</div>
                                                <div
                                                    style={styles.betCanWin}>{PublicUtils.subStringNum(this.state.canWin)}</div>
                                            </div>
                                            {
                                                this.state.balance ?

                                                    <div style={{
                                                        ...styles.betModalRow, ...{
                                                            marginLeft: 10,
                                                            height: 25,
                                                            fontSize: 16
                                                        }
                                                    }}>
                                                        <div style={{color: "rgba(0,0,0,0.4)", fontSize: 12}}>余额：</div>
                                                        <div
                                                            style={styles.betCanWin}>{PublicUtils.subStringNum(this.state.balance)}</div>
                                                    </div> : null
                                            }
                                        </div>
                                    </div>

                                    <div style={styles.amountTemplateWrapper}>
                                        {
                                            this.amountTemplate.map((item) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            this.setBetAmount(item.value);
                                                        }}
                                                        style={styles.amountTemplateBox}
                                                    >
                                                        <div>
                                                            <div
                                                                style={styles.amountTemplateText}>{item.name}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/*{*/}
                                    {/*this.state.betTips ?*/}
                                    {/*<View style={styles.betModalRow}>*/}
                                    {/*<Text*/}
                                    {/*style={styles.betTips}>{this.state.betTips}</Text>*/}
                                    {/*</View> : null*/}
                                    {/*}*/}
                                    <div style={styles.btnBox}>
                                        <div activeOpacity={0.4}
                                             disabled={this.state.showProgress}
                                             onClick={() => {
                                                 if (!this.state.showProgress) {
                                                     this.beting()
                                                 }
                                             }}>
                                            <div style={styles.btn}>
                                                {this.state.showProgress ? this._showProgress() : <div
                                                    style={{...styles.btnText, ...this.state.showProgress ? styles.disabledBtnText : null}}>立即投注</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}
